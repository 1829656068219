<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper" style="max-width: 550px; margin: auto">
      <div class="text-center font-16 m-t">
        Você está prestes a cancelar a venda do lote <strong>{{lote.numero}}</strong> do leilão <strong>{{lote.leilao.codigo}}</strong>
        - <strong>{{lote.descricao}}</strong><span v-if="lote.comitente"> do comitente {{lote.comitente.nome}}</span>
      </div>
      <div class="flex items-center justify-center column">
          <div class=" text-center m-t-lg">
            <div class="text-center font-bold m-b-xs">Informe o motivo do cancelamento</div>
            <motivo-cancelamento-venda-select :motivos="motivos" v-model="cancelamento.motivo2" class="btn-pda btn-pda-rounded md" />
          </div>

        <div class="text-center w-full">
          <div class="text-center font-bold m-b-xs">Classifique o motivo</div>
          <motivo-cancelamento-venda-select ref="motivo2" :motivos="motivos" v-model="cancelamento.motivo" is-subtipos :parent="cancelamento.motivo2" class="btn-pda btn-pda-rounded md" />
        </div>

        <div class="text-center w-full">
          <div class="text-center font-bold m-b-xs">Detalhe o motivo do cancelamento</div>
          <textarea v-model="cancelamento.descricao" rows="5" class="btn-pda btn-pda-rounded md w-full" style="min-height: 100px; padding: 12px 14px !important;"></textarea>
        </div>

        <div class="text-center i-red">
          <div class="text-center font-bold m-b-xs">Custo para cancelamento</div>
          <erp-input class="btn-pda btn-pda-rounded md" v-model="custoFake" readonly />
        </div>
      </div>

      <e-row class="m-t-lg m-l-n-lg m-r-n-lg items-center justify-center">
        <div>
          <u-btn @click="save" v-shortkey="['f10']" @shortkey.native="save" color="negative" size="lg"><span class="m-r-lg">Confirmar cancelamento</span> <span class="absolute-right font-11 m-r-sm flex items-center">F10</span> </u-btn>
          <u-btn @click="$uloc.window.close($root.wid)" color="grey" size="lg" label="Sair" class="m-l" />
        </div>
      </e-row>
    </div>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Venda cancelada com sucesso!</h5>
    <p class="m-t-xs">A multa por desistência foi registrada para o arrematante.</p>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  ErpInput
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../../layout/window/Loading'
import TimerCloseWindow from '../../../../layout/window/TimerClose'
import WindowSuccess from '../../../../layout/window/Success'
import MotivoCancelamentoVendaSelect from '../../include/MotivoCancelamentoVendaSelect'
import ERow from '../../../../layout/components/Row'
import {motivosCancelamentoVendaLote as list, atualizaStatusLote} from '../../../../../domain/leiloes/services'
import {STATUS_CANCELADO} from '../../../../../domain/leiloes/helpers/LoteStatus'

export default {
  name: 'CancelarVenda',
  props: ['lote'],
  data () {
    return {
      status: 'Cancelando venda',
      loading: false,
      loadingMotivos: false,
      errors: null,
      success: false,
      cancelamento: {
        id: this.lote.id,
        status: STATUS_CANCELADO,
        motivo: null,
        motivo2: null,
        descricao: null,
        custo: null
      },
      motivos: null,
      custoFake: null
    }
  },
  mounted () {
    this.loadMotivos()
  },
  watch: {
  },
  computed: {},
  methods: {
    loadMotivos () {
      this.loadingMotivos = true
      list()
        .then((response) => {
          this.loadingMotivos = false
          this.motivos = response.data.result

          let multa = response.data.multa
          let valor = this.lote.arremate.lance.valor
          let valorMulta = valor * (multa / 100)
          this.custoFake = multa + '% - R$ ' + this.$options.filters.moeda(valorMulta)
          /* if (this.isSubtipos) {
            data = this.subtipos
          } else {
            data = response.data
          } */
        })
        .catch((error) => {
          this.loadingMotivos = false

        })
    },
    save () {
      console.log('Save')
      this.loading = true
      atualizaStatusLote(this.lote.id, this.cancelamento)
        .then(response => {
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              this.loading = false
              this.success = false
              this.$uloc.window.emit(this.$root.wid, 'update')
              this.$uloc.window.close(this.$root.wid)
            })
          }
          cbSuccess()
        })
        .catch(({response}) => {
          console.log(response)
          this.loading = false
          this.alertApiError(response)
        })
    }
  },
  components: {
    ERow,
    MotivoCancelamentoVendaSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowContent,
    ErpInput
  }
}
</script>

<style lang="stylus">
  .i-red{
    input {
      color #CC2E2E !important
      font-weight bold
    }
  }
</style>
