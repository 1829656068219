<template>
  <erp-select
      @input="__set"
      :loading="loading"
      :placeholder="placeholder || 'Selecione'"
      :options="asyncdata"
      v-model="model"
      :multiple="multiple"
      :simpleBorder="simpleBorder"
  ></erp-select>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {motivosCancelamentoVendaLote as list} from '../../../../domain/leiloes/services'

export default {
  name: 'MotivoCancelamentoVendaSelect',
  mixins: [SelectMixin],
  props: {
    motivos: {
      required: true
    },
    isSubtipos: {
      required: false,
      type: Boolean,
      default: false
    },
    parent: {
      required: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    asyncdata () {
      let data = this.motivos
      if (!Array.isArray(data)) {
        return []
      }
      let list = data.filter(v => {
        if (this.isSubtipos){
          return v.parent && v.parent.id === this.parent
        }
        return !v.parent
      }).map((obj) => {
        return {
          ...obj,
          label: obj.nome,
          value: obj.id
        }
      })

      !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
      return list
    }
  },
  mounted () {
    // this.load()
  },
  components: {},
  methods: {
  }
}
</script>
